import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FullState } from 'main/reducers';
import {
  fetchAlarmsForReporting,
  updateAlarmParameter,
} from 'ducks/alarmparameters';
import AlarmParametersView from './alarmparametersview';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { useIsMounted, useShallowEqualSelector } from 'util/hooks';
import { usePlotState } from 'hooks/use-plot-state';
import { parseStringParamFromRouterProps } from 'util/routing';
import {
  resolveSettingsFromQuickplot,
  unmountPlotPage,
} from 'ducks/plot/scatter-time-series';
import { splitObservationPointItemUrlCode } from 'components/plots/timeseriesplotselectors';
import { isNotNull } from 'util/validation';
import { getCurrentDatetime, formatDatetimeForStorage } from 'util/dates';
import {
  fetchAlarmParameterScatterPlotData,
  RelativeAlarmScatterPlotData,
} from './relative-alarms-plot';
import { errorToString } from 'util/backendapi/error';
import {
  PlotSettingsAxis,
  QuickPlotAxisSide,
  TSPlotOnZoomFn,
  TSPlotZoomParams,
} from 'components/plots/timeseriesplot.types';

type RouterProps = RouteComponentProps<{ obsPointCode: string }>;

export const AlarmParameters = (props: RouterProps) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const { alarms, instrumentTypeItem, isLoadingAlarms } =
    useShallowEqualSelector((state: FullState) => {
      return {
        isLoadingAlarms: state.alarmParameters.detail.isLoading,
        instrumentTypeItem: state.alarmParameters.detail.instrumentTypeItem,
        alarms: state.alarmParameters.detail.alarms,
      };
    });
  const [scatterPlotsData, setScatterPlotsData] = useState<
    RelativeAlarmScatterPlotData[]
  >([]);
  const [scatterPlotsErrorMessage, setScatterPlotsErrorMessage] =
    useState<string>('');
  const [isLoadingScatterPlots, setIsLoadingScatterPlots] =
    useState<boolean>(false);

  const [quickPlotZoomParams, setQuickPlotZoomParams] =
    useState<TSPlotZoomParams | null>(null);

  const quickPlotState = usePlotState('quickPlot', Enum.PlotType.TIME_SERIES);
  const quickPlotResolvedSettings = quickPlotState.resolvedSettings;

  const obsItemNumbers = parseStringParamFromRouterProps(props, 'obsPointCode');

  const relativeAlarmParameters = useMemo(() => {
    const currentDatetime = getCurrentDatetime();
    return alarms?.filter(
      (param) =>
        param.comparison_type ===
          Enum.AlarmParameter_COMPARISON_TYPE.relative &&
        param.status === Enum.AlarmParameter_STATUS.enabled &&
        param.relative_observation_point__code &&
        param.relative_item_number &&
        formatDatetimeForStorage(param.start_datetime) <= currentDatetime &&
        (param.end_datetime === null ||
          formatDatetimeForStorage(param.end_datetime) > currentDatetime)
    );
  }, [alarms]);

  const pairs = useMemo(() => {
    return obsItemNumbers
      .split(',')
      .map((pair) => splitObservationPointItemUrlCode(pair))
      .filter(isNotNull);
  }, [obsItemNumbers]);

  useEffect(() => {
    // Load / reload the quick plot (e.g. if the zoom changes)
    if (pairs.length) {
      const startDatetime = quickPlotZoomParams
        ? quickPlotZoomParams.minDatetime
        : '';
      const endDatetime = quickPlotZoomParams
        ? quickPlotZoomParams.maxDatetime
        : '';
      const yAxes =
        (quickPlotZoomParams?.yAxes as PlotSettingsAxis<QuickPlotAxisSide>[]) ??
        [];

      dispatch(
        resolveSettingsFromQuickplot(
          pairs,
          startDatetime,
          endDatetime,
          '',
          yAxes,
          null,
          false,
          false,
          false
        )
      );
    }
  }, [dispatch, pairs, quickPlotZoomParams, props]);

  const fetchRelativeAlarmPlotData = useCallback(async () => {
    setScatterPlotsData([]);
    setScatterPlotsErrorMessage('');

    if (relativeAlarmParameters && relativeAlarmParameters.length > 0) {
      setIsLoadingScatterPlots(true);
      try {
        const scatterPlotData = await fetchAlarmParameterScatterPlotData(
          relativeAlarmParameters
        );
        if (!isMounted()) return;

        setScatterPlotsData(scatterPlotData);
      } catch (e) {
        setScatterPlotsErrorMessage(errorToString(e));
      }
    }
    setIsLoadingScatterPlots(false);
  }, [
    isMounted,
    relativeAlarmParameters,
    setIsLoadingScatterPlots,
    setScatterPlotsErrorMessage,
    setScatterPlotsData,
  ]);

  useEffect(() => {
    fetchRelativeAlarmPlotData();
  }, [dispatch, fetchRelativeAlarmPlotData, relativeAlarmParameters]);

  const observationPointId =
    quickPlotResolvedSettings?.observationPoints[0]?.id;
  const itemNumber = quickPlotResolvedSettings?.reading_series[0]?.item_number;

  const fetchData = useCallback(() => {
    // Fetch alarms when observationPoint or itemNumber change
    if (observationPointId && itemNumber) {
      dispatch(fetchAlarmsForReporting(observationPointId, itemNumber, '', ''));
    }
  }, [dispatch, observationPointId, itemNumber]);

  useEffect(() => {
    fetchData();
  }, [dispatch, observationPointId, itemNumber, fetchData]);

  const enableAlarm = useCallback(
    async (id: number) => {
      await dispatch(
        updateAlarmParameter(id, {
          status: Enum.AlarmParameter_STATUS.enabled,
        })
      );
      fetchData();
    },
    [dispatch, fetchData]
  );

  const disableAlarm = useCallback(
    async (id: number) => {
      await dispatch(
        updateAlarmParameter(id, {
          status: Enum.AlarmParameter_STATUS.disabled,
        })
      );
      fetchData();
    },
    [dispatch, fetchData]
  );

  const quickPlotZoom = useCallback<TSPlotOnZoomFn>(
    (zoomParams) => {
      setQuickPlotZoomParams(zoomParams);
    },
    [setQuickPlotZoomParams]
  );

  const resetQuickPlotZoom = useCallback(() => {
    setQuickPlotZoomParams(null);
  }, [setQuickPlotZoomParams]);

  // Clean up plot data when unmounting
  useEffect(
    () => () => {
      dispatch(unmountPlotPage());
    },
    [dispatch]
  );

  const errorMessage = quickPlotState.errorMessage || scatterPlotsErrorMessage;

  const hasReadingSeriesErrorMessage = quickPlotState.readingsSeries.some(
    (rs) => rs.errorMessage && rs.errorMessage !== ''
  );

  const isLoadingQuickPlot =
    quickPlotState.isLoading && !hasReadingSeriesErrorMessage;

  return (
    <AlarmParametersView
      obsPointCode={pairs.length ? pairs[0].observationPointCode : ''}
      readings={quickPlotState.readingsSeries}
      alarms={alarms}
      instrumentTypeItem={instrumentTypeItem}
      isLoadingQuickPlot={isLoadingQuickPlot}
      isLoadingAlarms={isLoadingAlarms}
      isLoadingScatterPlots={isLoadingScatterPlots}
      scatterPlotsData={scatterPlotsData}
      errorMessage={errorMessage}
      refreshAlarmsList={fetchData}
      enableAlarm={enableAlarm}
      disableAlarm={disableAlarm}
      onQuickPlotZoom={quickPlotZoom}
      onResetQuickPlotZoom={quickPlotZoomParams ? resetQuickPlotZoom : null}
      paddedMinDatetime={
        quickPlotZoomParams
          ? quickPlotZoomParams.minDatetime
          : quickPlotState.paddedMinDatetime
      }
      paddedMaxDatetime={
        quickPlotZoomParams
          ? quickPlotZoomParams.maxDatetime
          : quickPlotState.paddedMaxDatetime
      }
      yAxes={quickPlotZoomParams?.yAxes ?? []}
    />
  );
};

export default AlarmParameters;
