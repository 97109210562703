import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans } from '@lingui/macro';
import { Form, Field, Formik, FormikErrors } from 'formik';
import { FormCard, FormCardSection } from 'components/base/card/card';
import ActionBlock from 'components/base/actionblock/actionblock';
import Button, { ButtonPrimary } from 'components/base/button/button';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { AreaMultiMenu } from 'components/modules/async-menu/AreaMenu';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import {
  ChecksheetTemplate_FREQUENCY,
  ChecksheetTemplate_STATUS,
} from 'util/backendapi/types/Enum';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import { FetcherState, useGetApi } from 'hooks/use-get-api';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields, errorToString } from 'util/backendapi/error';
import { useHistory, useParams } from 'react-router';
import Loading from 'components/base/loading/loading';
import { AlertWarning } from 'components/base/alert/alert';
import { postApi, patchApi } from 'util/backendapi/fetch';
import {
  ChecksheetTemplate_POST,
  ChecksheetTemplate,
} from 'util/backendapi/types/Model';
import { YesNoToggleField } from 'components/base/form/toggle-field/ToggleField';

export function ChecksheetTemplateEditScreen(props: any) {
  const history = useHistory();
  const routeParams = useParams<{ checksheetTemplateId?: string }>();

  const checksheetTemplateId = routeParams.checksheetTemplateId
    ? Number(routeParams.checksheetTemplateId)
    : null;

  const handleSubmit = useCallback(
    async (checksheetTemplate: ChecksheetTemplate_POST) => {
      if (checksheetTemplateId) {
        await patchApi(
          `/checksheet-templates/${checksheetTemplateId}/`,
          checksheetTemplate
        );
      } else {
        await postApi('/checksheet-templates/', checksheetTemplate);
      }
      history.push('/checksheet-templates/');
    },
    [checksheetTemplateId, history]
  );

  const handleCancel = useCallback(
    () => history.push('/checksheet-templates/'),
    [history]
  );

  const [checksheetTemplate] = useGetApi(
    checksheetTemplateId === null
      ? null
      : `/checksheet-templates/${checksheetTemplateId}/`
  );
  const [plotSets] = useGetApi('/plot-sets/', {
    fields: ['id', 'name', 'areas'],
  });

  const [checksheetInstancesInProgress] = useGetApi(
    checksheetTemplateId !== null ? `/checksheet-instances-simple/` : null,
    checksheetTemplateId !== null
      ? {
          template: checksheetTemplateId,
          status__in: [Enum.ChecksheetInstance_STATUS.in_progress],
        }
      : undefined
  );

  const [routeMarches] = useGetApi('/route-marches/');
  const [dataLoggers] = useGetApi('/data-loggers/');

  const multipleInstancesInProgress = checksheetInstancesInProgress?.data
    ? checksheetInstancesInProgress.data.length > 1
    : false;

  return (
    <ChecksheetTemplateEditView
      errorMessage={
        checksheetTemplate.error ||
        plotSets.error ||
        routeMarches.error ||
        dataLoggers.error
      }
      isLoading={
        checksheetTemplate.isLoading ||
        plotSets.isLoading ||
        routeMarches.isLoading ||
        dataLoggers.isLoading
      }
      isNew={checksheetTemplateId === null}
      checksheetTemplate={checksheetTemplate.data}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      plotSets={plotSets}
      routeMarches={routeMarches}
      dataLoggers={dataLoggers}
      multipleInstancesInProgress={multipleInstancesInProgress}
    />
  );
}

type FormValues = ChecksheetTemplate_POST;

interface ViewProps {
  isNew: boolean;
  checksheetTemplate: ChecksheetTemplate | null;
  errorMessage?: any;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (checksheetTemplate: ChecksheetTemplate_POST) => void;
  plotSets: FetcherState<Model.PlotSet[]>;
  routeMarches: FetcherState<Model.RouteMarch[]>;
  dataLoggers: FetcherState<Model.DataLoggerDecorated[]>;
  multipleInstancesInProgress: boolean;
}

export function ChecksheetTemplateEditView(props: ViewProps) {
  const [areaPlotSetOptions, setAreaPlotSetOptions] = useState<
    SimpleSelectOption<number>[]
  >([]);
  const [areaRouteMarchOptions, setAreaRouteMarchOptions] = useState<
    SimpleSelectOption<number>[]
  >([]);
  const [areaDataLoggerOptions, setAreaDataLoggerOptions] = useState<
    SimpleSelectOption<number>[]
  >([]);

  const filterMenuOptionsByArea = useCallback(
    (areas: number[]) => {
      setAreaPlotSetOptions(
        props.plotSets.data
          ?.filter((ps) => ps.areas.some((a) => areas.includes(a.id)))
          .map((ps) => ({
            value: ps.id,
            label: ps.name,
          })) ?? []
      );
      setAreaRouteMarchOptions(
        props.routeMarches.data
          ?.filter((rm) => rm.areas.some((areaId) => areas.includes(areaId)))
          .map((rm) => ({
            value: rm.id,
            label: rm.code,
          })) ?? []
      );
      setAreaDataLoggerOptions(
        props.dataLoggers.data
          ?.filter((dl) => areas.includes(dl.site.area.id))
          .map((dl) => ({
            value: dl.id,
            label: dl.logger_number,
          })) ?? []
      );
    },
    [props.dataLoggers.data, props.plotSets.data, props.routeMarches.data]
  );

  useEffect(() => {
    if (props.checksheetTemplate) {
      filterMenuOptionsByArea(
        props.checksheetTemplate.areas.map(({ id }) => id)
      );
    }
  }, [filterMenuOptionsByArea, props.checksheetTemplate]);

  const initialValues: FormValues = useMemo(
    () =>
      props.isNew || !props.checksheetTemplate
        ? {
            name: '',
            areas: [],
            description: '',
            frequency: null as any,
            notes: '',
            plot_set: 0,
            route_marches: [],
            data_loggers: [],
            status: ChecksheetTemplate_STATUS.active,
            allow_other_batches: false,
            allow_multiple_instances: false,
          }
        : {
            ...props.checksheetTemplate,
            areas: props.checksheetTemplate.areas.map(({ id }) => id),
          },
    [props.checksheetTemplate, props.isNew]
  );

  return (
    <PageStandard
      name="edit-checksheet-template"
      header={
        props.isNew ? (
          <Trans>Create Checksheet Template</Trans>
        ) : (
          <Trans>Edit Checksheet Template</Trans>
        )
      }
    >
      {props.errorMessage && !props.isLoading ? (
        <AlertWarning>{errorToString(props.errorMessage)}</AlertWarning>
      ) : props.isLoading || (!props.isNew && !props.checksheetTemplate) ? (
        <Loading />
      ) : (
        <Formik<FormValues>
          initialValues={initialValues}
          onSubmit={async (values, formik) => {
            try {
              await props.onSubmit(values);
            } catch (e) {
              formik.setSubmitting(false);
              showErrorsInFormik(formik, e, getExpectedFields(values));
            }
          }}
          validate={(values) => {
            const errors: FormikErrors<FormValues> = {};
            if (!values.name) {
              errors.name = (<Trans>Name is required.</Trans>) as any;
            }
            if (!values.areas.length) {
              errors.areas = (
                <Trans>At least one area is required.</Trans>
              ) as any;
            }
            if (!values.frequency) {
              errors.frequency = (<Trans>Frequency is required.</Trans>) as any;
            }
            if (!values.plot_set) {
              errors.plot_set = (<Trans>Plot set is required.</Trans>) as any;
            }
            if (!values.status) {
              errors.status = (<Trans>Status is required.</Trans>) as any;
            }

            if (
              props.checksheetTemplate?.allow_multiple_instances &&
              !values.allow_multiple_instances &&
              props.multipleInstancesInProgress
            ) {
              errors.allow_multiple_instances = (
                <Trans>
                  Please complete or delete any additional instances of this
                  template before changing this setting to No.
                </Trans>
              ) as any;
            }

            return errors;
          }}
        >
          {(formik) => {
            const buttons = (
              <ActionBlock>
                <Button
                  data-testid="edit-checksheet-template-cancel"
                  onClick={props.onCancel}
                >
                  <Trans>Cancel</Trans>
                </Button>
                <ButtonPrimary
                  data-testid="edit-checksheet-template-submit"
                  iconType="icon-save"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              </ActionBlock>
            );

            return (
              <Form>
                {formik.status}
                <FormCard
                  name="edit-checksheet-template"
                  header={
                    props.isNew ? (
                      <Trans>New checksheet template</Trans>
                    ) : (
                      <Trans>Edit checksheet template</Trans>
                    )
                  }
                  subHeader={buttons}
                >
                  <FormCardSection
                    name="general"
                    header={<Trans>General</Trans>}
                    fields={[
                      {
                        name: 'name',
                        label: <Trans>Name</Trans>,
                        content: (
                          <>
                            <Field name="name" />
                            <FieldError name="name" />
                          </>
                        ),
                      },
                      {
                        name: 'areas',
                        label: <Trans>Areas</Trans>,
                        content: (
                          <>
                            <AreaMultiMenu name="areas" />
                            <FieldError name="areas" />
                            <FormChangeEffect<FormValues>
                              onChange={(prevProps) => {
                                const curAreas = formik.values.areas;
                                const prevAreas = prevProps.values.areas;
                                if (curAreas === prevAreas) {
                                  return;
                                }

                                filterMenuOptionsByArea(curAreas);
                                formik.setFieldValue('plot_set', 0);
                                formik.setFieldValue('route_marches', []);
                                formik.setFieldValue('data_loggers', []);
                              }}
                            />
                          </>
                        ),
                      },
                      {
                        name: 'description',
                        label: <Trans>Description</Trans>,
                        content: (
                          <>
                            <Field
                              data-testid="general-description"
                              component="textarea"
                              maxLength={5000}
                              name="description"
                            />
                            <FieldError name="description" />
                          </>
                        ),
                      },
                      {
                        name: 'frequency',
                        label: <Trans>Frequency</Trans>,
                        content: (
                          <>
                            <SimpleSelectField
                              name="frequency"
                              options={menuItemsFromEnum(
                                'ChecksheetTemplate_FREQUENCY',
                                Object.values(ChecksheetTemplate_FREQUENCY)
                              )}
                            />
                            <FieldError name="frequency" />
                          </>
                        ),
                      },
                      {
                        name: 'notes',
                        label: <Trans>Notes</Trans>,
                        content: (
                          <>
                            <Field
                              data-testid="general-notes"
                              component="textarea"
                              maxLength={5000}
                              name="notes"
                            />
                            <FieldError name="notes" />
                          </>
                        ),
                      },
                      {
                        name: 'status',
                        label: <Trans>Status</Trans>,
                        content: (
                          <>
                            <SimpleSelectField
                              name="status"
                              options={menuItemsFromEnum(
                                'ChecksheetTemplate_STATUS',
                                Object.values(ChecksheetTemplate_STATUS)
                              )}
                            />
                            <FieldError name="status" />
                          </>
                        ),
                      },
                      {
                        name: 'allow_multiple_instances',
                        label: <Trans>Allow multiple instances</Trans>,
                        content: (
                          <>
                            <YesNoToggleField name="allow_multiple_instances" />
                            <FieldError name="allow_multiple_instances" />
                          </>
                        ),
                      },
                    ]}
                  />
                  <FormCardSection
                    name="plot-set"
                    header={<Trans>Plot set</Trans>}
                    fields={[
                      {
                        name: 'plot_set',
                        label: <Trans>Plot set</Trans>,
                        content: (
                          <>
                            <SimpleSelectField<number, false>
                              name="plot_set"
                              isMulti={false}
                              isDisabled={!formik.values.areas.length}
                              options={areaPlotSetOptions}
                              maxMenuHeight={230} // prevent from extending below bottom of page
                            />
                            <FieldError name="plot_set" />
                          </>
                        ),
                      },
                    ]}
                  />
                  <FormCardSection
                    name="data-sources"
                    header={<Trans>Data sources</Trans>}
                    fields={[
                      {
                        name: 'route_marches',
                        label: <Trans>Route marches</Trans>,
                        content: (
                          <>
                            <SimpleSelectField<number, true>
                              name="route_marches"
                              isMulti={true}
                              isDisabled={!formik.values.areas.length}
                              options={areaRouteMarchOptions}
                              maxMenuHeight={150} // prevent from extending below bottom of page
                            />
                            <FieldError name="route_marches" />
                          </>
                        ),
                      },
                      {
                        name: 'data_loggers',
                        label: <Trans>Data loggers</Trans>,
                        content: (
                          <>
                            <SimpleSelectField<number, true>
                              name="data_loggers"
                              isMulti={true}
                              isDisabled={!formik.values.areas.length}
                              options={areaDataLoggerOptions}
                              maxMenuHeight={100} // prevent from extending below bottom of page
                            />
                            <FieldError name="route_marches" />
                          </>
                        ),
                      },
                      {
                        name: 'allow_other_batches',
                        label: <Trans>Accept other batches</Trans>,
                        content: (
                          <>
                            <YesNoToggleField name="allow_other_batches" />
                            <FieldError name="allow_other_batches" />
                          </>
                        ),
                      },
                    ]}
                  />
                  <FormCardSection name="card-actions-bottom">
                    {buttons}
                  </FormCardSection>
                </FormCard>
              </Form>
            );
          }}
        </Formik>
      )}
    </PageStandard>
  );
}
