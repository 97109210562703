import React, { useEffect, useState } from 'react';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, Form } from 'formik';
import { Model } from 'util/backendapi/models/api.interfaces';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { FetcherState, useGetApi } from 'hooks/use-get-api';
import { errorToString, getExpectedFields } from 'util/backendapi/error';
import Loading from 'components/base/loading/loading';
import { AlertWarning } from 'components/base/alert/alert';
import { getApi, patchApi, postApi } from 'util/backendapi/fetch';
import {
  ChecksheetInstance_STATUS,
  ChecksheetTemplate_STATUS,
} from 'util/backendapi/types/Enum';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import { formatDatetimeForDisplay } from 'util/dates';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getUserDisplayName } from 'util/user';
import { RadioField } from 'components/base/form/radio-field/RadioField';
import { ChecksheetInstance } from 'util/backendapi/types/Model';

interface Props extends ModalContentProps {
  readingsBatch: Model.ListReadingsBatch;
  onAfterSave: () => void;
}

type FormValues = {
  checksheet_template_id: number | null;
  checksheet_instance_id: number | null;
  other_checksheet_instance_id: number | null;
};

async function fetchMenuOptions(readingsBatch: Model.ListReadingsBatch) {
  let checksheetTemplateOptions = null;
  if (readingsBatch.route_march) {
    const routeMarchChecksheetTemplates = await getApi(
      '/checksheet-templates/',
      {
        route_marches: readingsBatch.route_march!.id,
        status__in: [ChecksheetTemplate_STATUS.active],
      }
    );
    checksheetTemplateOptions = routeMarchChecksheetTemplates.map(
      (tmpl): SimpleSelectOption<number> => ({
        value: tmpl.id,
        label: `${tmpl.name}`,
      })
    );
  }

  const allowOtherBatchesChecksheetInstances = await getApi(
    '/reports/checksheet-instances/',
    {
      template__allow_other_batches: true,
      status__in: [ChecksheetInstance_STATUS.in_progress],
      columns: ['template__name', 'id'],
    }
  );

  const otherChecksheetInstanceOptions =
    allowOtherBatchesChecksheetInstances.map(
      (instance): SimpleSelectOption<number> => ({
        value: instance.id,
        label: `${instance.template__name}`,
      })
    );

  return {
    checksheetTemplateOptions,
    otherChecksheetInstanceOptions,
  };
}

export function AssignToChecksheetInstanceModal(props: Props) {
  const { readingsBatch } = props;

  const initialValues: FormValues = {
    checksheet_template_id: null,
    checksheet_instance_id: null,
    other_checksheet_instance_id: null,
  };

  const header = <Trans>Assign to checksheet</Trans>;

  const [checksheetInstanceOptions, setChecksheetInstanceOptions] = useState<
    SimpleSelectOption<number>[] | null
  >(null);

  const [areas] = useGetApi('/areas/');

  const fetchChecksheetInstanceOptions = async (
    checksheet_template_id: number | null
  ): Promise<SimpleSelectOption<number>[]> => {
    if (checksheet_template_id === null) {
      return [];
    }
    const response = await getApi('/reports/checksheet-instances/', {
      columns: [
        'created_datetime',
        'created_by',
        'template__areas__code',
        'name',
        'id',
      ],
      template: checksheet_template_id!,
      status__in: [ChecksheetInstance_STATUS.in_progress],
      ordering: ['-created_datetime'],
    });

    return response.map((instance) => {
      // Extract Mon/YY from the suffix of the name if present
      const suffixMatch = instance.name.match(/\[([a-z]{3}\/\d{2})\]$/i);
      const observationDate = suffixMatch?.[1] ?? null;

      const areaCode = instance.template__areas__code.split(',')[0];

      const timeZone =
        areas.data?.find(({ code }) => code === areaCode)?.time_zone.name ??
        null;

      const label = (
        <>
          {observationDate ? <>{observationDate} </> : null}
          <Trans>
            Started:{' '}
            {formatDatetimeForDisplay(instance.created_datetime, timeZone)} by{' '}
            {getUserDisplayName(instance.created_by)}
          </Trans>
        </>
      );

      return {
        value: instance.id,
        label,
      };
    });
  };

  const validate = (values: FormValues) => {
    const errors: any = {};

    if (
      !values.checksheet_template_id &&
      !values.other_checksheet_instance_id
    ) {
      errors.checksheet_template_id = <Trans>Checksheet is required.</Trans>;
    }

    if (values.checksheet_template_id && values.other_checksheet_instance_id) {
      errors.other_checksheet_instance_id = (
        <Trans>
          Cannot assign the same batch to multiple checksheets. Select either a
          checksheet under Checksheet or Other Batches, not both.
        </Trans>
      );
    } else if (
      values.checksheet_template_id &&
      checksheetInstanceOptions &&
      checksheetInstanceOptions.length > 0 &&
      !values.checksheet_instance_id
    ) {
      // Currently If there are already instances of this checksheet template in progress you must select one.
      // This will probably change
      errors.checksheet_instance_id = (
        <Trans>Checksheet instance is required.</Trans>
      );
    }
    return errors;
  };

  const [menuOptions, setMenuOptions] = useState<
    FetcherState<{
      checksheetTemplateOptions: SimpleSelectOption<number>[] | null;
      otherChecksheetInstanceOptions: SimpleSelectOption<number>[];
    }>
  >({
    isError: false,
    isLoading: true,
    data: null,
  });
  useEffect(() => {
    setMenuOptions({
      isError: false,
      isLoading: true,
      data: null,
    });

    fetchMenuOptions(readingsBatch)
      .then((data) =>
        setMenuOptions({
          isError: false,
          isLoading: false,
          data,
        })
      )
      .catch((e) =>
        setMenuOptions({
          isError: true,
          isLoading: false,
          error: errorToString(e),
          data: null,
        })
      );
  }, [readingsBatch]);

  if (menuOptions.isLoading || areas.isLoading || !menuOptions.data) {
    return (
      <ModalContent header={header}>
        <Loading />
      </ModalContent>
    );
  }
  if (menuOptions.isError) {
    return (
      <ModalContent header={header}>
        <AlertWarning>{menuOptions.error}</AlertWarning>
      </ModalContent>
    );
  }

  const { checksheetTemplateOptions, otherChecksheetInstanceOptions } =
    menuOptions.data;

  return (
    <ModalContent header={header}>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={async (values, formik) => {
          try {
            const checksheet_instance_id =
              values.checksheet_instance_id ||
              values.other_checksheet_instance_id;

            if (checksheet_instance_id) {
              // Find the existing data source associated with the batch's route march (if there is one)
              // and add the batch to it (unless it already has it)
              const checksheetInstance: ChecksheetInstance = await getApi(
                `/checksheet-instances/${checksheet_instance_id}/`
              );
              const routeMarchdataSource = checksheetInstance.data_sources.find(
                (dataSource) =>
                  dataSource.route_march?.id === readingsBatch.route_march!.id
              );
              if (routeMarchdataSource) {
                let batchIds = routeMarchdataSource.batches.map(
                  (batch) => batch.id
                );

                if (!batchIds.some((id) => id === readingsBatch.id)) {
                  batchIds.push(readingsBatch.id);
                  await patchApi(
                    `/checksheet-data-sources/${routeMarchdataSource.id}/`,
                    {
                      batches: batchIds,
                      instance: checksheetInstance.id,
                    }
                  );
                }
              } else {
                // Add batch to instance by creating a new data source
                await postApi('/checksheet-data-sources/', {
                  batches: [readingsBatch.id],
                  instance: checksheet_instance_id,
                });
              }
            } else {
              // Otherwise create a new instance
              await postApi('/checksheet-instances/', {
                template: values.checksheet_template_id!,
                batch: readingsBatch.id,
              });
            }
          } catch (e) {
            showErrorsInFormik(formik, e, getExpectedFields(values));
            formik.setSubmitting(false);
            return;
          }

          props.hideModal();
          props.onAfterSave();
        }}
      >
        {(formik) => {
          return (
            <Form>
              {formik.status}

              <FormSection>
                <FormItem label={<Trans>Batch</Trans>}>
                  <span>{readingsBatch.batch_number}</span>
                </FormItem>
                {readingsBatch.route_march ? (
                  <FormItem label={<Trans>Route march</Trans>}>
                    <span>{readingsBatch.route_march!.code}</span>
                  </FormItem>
                ) : null}
                <FormItem label={<Trans>Checksheet</Trans>}>
                  {checksheetTemplateOptions &&
                  checksheetTemplateOptions.length > 0 ? (
                    <>
                      <SimpleSelectField<number>
                        placeholder={<Trans>Select a checksheet</Trans>}
                        name="checksheet_template_id"
                        isMulti={false}
                        options={checksheetTemplateOptions}
                        isClearable={true}
                      />
                      <FieldError name="checksheet_template_id" />
                    </>
                  ) : (
                    <Trans>This route march is not found on a checksheet</Trans>
                  )}
                  {formik.values.checksheet_template_id &&
                  checksheetTemplateOptions &&
                  checksheetTemplateOptions.length > 0 ? (
                    <FormItem label={<Trans>Instance</Trans>}>
                      {checksheetInstanceOptions?.length === 1 ? (
                        <span>{checksheetInstanceOptions[0].label}</span>
                      ) : checksheetInstanceOptions &&
                        checksheetInstanceOptions.length > 1 ? (
                        <>
                          <RadioField
                            name="checksheet_instance_id"
                            options={checksheetInstanceOptions}
                          />
                          <FieldError name="checksheet_instance_id" />
                        </>
                      ) : (
                        <Trans>A new instance will be created</Trans>
                      )}
                    </FormItem>
                  ) : null}
                </FormItem>
                {otherChecksheetInstanceOptions.length > 0 ? (
                  <FormItem label={<Trans>Other batches</Trans>}>
                    <SimpleSelectField<number>
                      placeholder={<Trans>Select a checksheet instance</Trans>}
                      name="other_checksheet_instance_id"
                      isMulti={false}
                      options={otherChecksheetInstanceOptions}
                      isClearable={true}
                    />
                    <FieldError name="other_checksheet_instance_id" />
                  </FormItem>
                ) : null}
              </FormSection>
              <FormChangeEffect<FormValues>
                onChange={({ values: prevValues }) => {
                  if (
                    formik.values.checksheet_template_id !==
                    prevValues.checksheet_template_id
                  ) {
                    formik.setFieldValue('checksheet_instance_id', null);
                    fetchChecksheetInstanceOptions(
                      formik.values.checksheet_template_id
                    ).then((options) => {
                      setChecksheetInstanceOptions(options);
                      if (options.length === 1) {
                        // Currently if there is only one instance we select it and don't show a widget
                        formik.setFieldValue(
                          'checksheet_instance_id',
                          options[0].value
                        );
                      }
                    });
                  }
                }}
              />
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  type="submit"
                  iconType="icon-save"
                  disabled={formik.isSubmitting}
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          );
        }}
      </Formik>
    </ModalContent>
  );
}
