import React, { useMemo } from 'react';

import { FieldArray, FormikHelpers } from 'formik';
import { SectionProps } from '../../maintobspoint.types';
import { Trans } from '@lingui/macro';
import EditableCard from 'components/base/form/editablecard/editablecard';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import './PerformanceIndicatorSection.scss';
import Button from 'components/base/button/button';
import { useGetApi } from 'hooks/use-get-api';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import classNames from 'classnames';
import { PerformanceIndicator_STATUS } from 'util/backendapi/types/Enum';

export interface PerformanceIndicatorFormValues {
  performance_indicators: number[];
  performance_indicator: number;
}

export function ObsPointPerformanceIndicatorSection(props: SectionProps) {
  const shouldDisable =
    props.isDisabled || props.isLoading || props.isSubmitting;

  const isEditing = props.isEditing;

  const handleSubmit = React.useCallback(
    async (
      values: PerformanceIndicatorFormValues,
      formik: FormikHelpers<PerformanceIndicatorFormValues>
    ) => {
      try {
        const submitValues = {
          performance_indicators: values.performance_indicators.map(
            (id) => +id
          ),
        };
        await props.onSubmit.call(null, submitValues);
      } catch (e) {
        formik.setSubmitting(false);
        showErrorsInFormik(formik, e, getExpectedFields(values));
      }
    },
    [props.onSubmit]
  );

  const [performanceIndicatorsDetail] = useGetApi('/performance-indicators/', {
    status: PerformanceIndicator_STATUS.active,
  });

  const { performanceIndicatorOptions, performanceIndicatorIdToName } =
    useMemo(() => {
      if (!performanceIndicatorsDetail.data) {
        return {
          performanceIndicatorOptions: [],
          performanceIndicatorIdToName: {},
        };
      }

      const performanceIndicatorOptions = performanceIndicatorsDetail.data.map(
        (p) => ({ label: p.name, value: p.id })
      );

      let performanceIndicatorIdToName: Record<number, string> = {};
      performanceIndicatorsDetail.data.forEach(
        (p) => (performanceIndicatorIdToName[p.id] = p.name)
      );

      return { performanceIndicatorOptions, performanceIndicatorIdToName };
    }, [performanceIndicatorsDetail]);

  const initialValues = useMemo(() => {
    // Don't show retired performance indicators
    const activePerformanceIndicators =
      props.observationPoint?.performance_indicators?.filter(
        (id) => id in performanceIndicatorIdToName
      ) ?? ([] as number[]);

    const initialDefaultPerformanceIndicator =
      performanceIndicatorOptions.find(
        ({ value }) =>
          activePerformanceIndicators.findIndex((id) => id === value) === -1
      )?.value ?? 0;

    return {
      performance_indicators: activePerformanceIndicators,
      performance_indicator: initialDefaultPerformanceIndicator,
    };
  }, [
    props.observationPoint,
    performanceIndicatorOptions,
    performanceIndicatorIdToName,
  ]);

  return (
    <EditableCard
      name="performance-indicators-card"
      header={<Trans>Observation point performance indicators</Trans>}
      shouldDisable={shouldDisable}
      hasEditPermission={props.hasEditPermission}
      isEditMode={isEditing}
      enableReinitialize={!isEditing}
      startEditing={props.startEditing}
      stopEditing={props.stopEditing}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ formik, CardSectionComponent }) => {
        const filteredPerformanceIndicatorOptions =
          performanceIndicatorOptions.filter(
            ({ value }) =>
              formik.values.performance_indicators.findIndex(
                (id) => id === value
              ) === -1
          );

        return (
          <>
            <FieldArray name="performance_indicators">
              {(formikArrayHelpers) => {
                return (
                  <CardSectionComponent
                    name="performance-indicators-section"
                    header={<Trans>Performance indicators</Trans>}
                    fields={[
                      {
                        name: 'obs-point-performance-indicators-table',
                        content: (
                          <>
                            <DragDropContext
                              onDragEnd={(result: DropResult) => {
                                if (
                                  result.destination &&
                                  result.source.index !==
                                    result.destination.index
                                ) {
                                  formikArrayHelpers.move(
                                    result.source.index,
                                    result.destination.index
                                  );
                                }
                              }}
                            >
                              <table>
                                <Droppable droppableId="obs-point-performance-indicator-droppable">
                                  {(provided) => (
                                    <tbody
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {formik.values.performance_indicators.map(
                                        (performanceIndicatorId, idx) => (
                                          <Draggable
                                            key={performanceIndicatorId}
                                            draggableId={String(
                                              performanceIndicatorId
                                            )}
                                            index={idx}
                                            isDragDisabled={!isEditing}
                                          >
                                            {(provided, snapshot) => (
                                              <tr
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                className={classNames({
                                                  draggable: isEditing,
                                                  dragging: snapshot.isDragging,
                                                })}
                                              >
                                                <td className="obs-point-performance-indicator-edit-item">
                                                  {
                                                    performanceIndicatorIdToName[
                                                      performanceIndicatorId
                                                    ]
                                                  }
                                                  {isEditing ? (
                                                    <Button
                                                      disabled={shouldDisable}
                                                      name={`obs-point-performance-indicator-${idx}-delete`}
                                                      onClick={() =>
                                                        formikArrayHelpers.remove(
                                                          idx
                                                        )
                                                      }
                                                    >
                                                      <Trans>Delete</Trans>
                                                    </Button>
                                                  ) : null}
                                                </td>
                                              </tr>
                                            )}
                                          </Draggable>
                                        )
                                      )}
                                      {provided.placeholder}
                                    </tbody>
                                  )}
                                </Droppable>
                              </table>
                            </DragDropContext>
                            {isEditing &&
                            filteredPerformanceIndicatorOptions.length > 0 ? (
                              <div
                                key="obs-point-performance-indicator-add"
                                className="card-row"
                              >
                                <div className="obs-point-performance-indicator-add-item">
                                  <Button
                                    name="obs-point-performance-indicator-add-button"
                                    iconType="icon-plus"
                                    disabled={shouldDisable}
                                    onClick={() => {
                                      if (
                                        formik.values.performance_indicator > 0
                                      ) {
                                        formikArrayHelpers.push(
                                          formik.values.performance_indicator
                                        );
                                      }
                                    }}
                                  >
                                    <Trans>Add</Trans>
                                  </Button>
                                  <div id="obs-point-performance-indicator-add-select-outer">
                                    <SimpleSelectField<number>
                                      data-testid="obs-point-performance-indicator-add-menu"
                                      options={
                                        filteredPerformanceIndicatorOptions
                                      }
                                      name="performance_indicator"
                                      isDisabled={shouldDisable}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        ),
                      },
                    ]}
                  >
                    {!props.isEditing &&
                      !props.isLoading &&
                      formik.values.performance_indicators.length === 0 && (
                        <p className="non-editable-value">
                          <Trans>
                            There are no performance indicators for this
                            observation point
                          </Trans>
                        </p>
                      )}
                    {formik.status}
                  </CardSectionComponent>
                );
              }}
            </FieldArray>
            <FormChangeEffect
              onChange={(prevFormikState) => {
                if (
                  prevFormikState.values.performance_indicators !==
                  formik.values.performance_indicators
                ) {
                  // Update default selected performance indicator
                  const newDefaultPerformanceIndicator =
                    performanceIndicatorOptions.find(
                      ({ value }) =>
                        formik.values.performance_indicators.findIndex(
                          (id) => id === value
                        ) === -1
                    )?.value ?? 0;
                  formik.setFieldValue(
                    'performance_indicator',
                    newDefaultPerformanceIndicator
                  );
                }
              }}
            />
          </>
        );
      }}
    />
  );
}
