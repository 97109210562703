import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router';
import { getApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import Loading from 'components/base/loading/loading';
import { AlertWarning } from 'components/base/alert/alert';
import { Enum } from 'util/backendapi/models/api.interfaces';

/**
 * A "redirect" screen, for when the user clicks on the "resume checksheet" button on the checksheet templates list screen.
 */
export function ChecksheetTemplateResumeInstanceScreen() {
  const { checksheetTemplateId } = useParams<{
    checksheetTemplateId: string;
  }>();

  const [instanceId, setInstanceId] = useState<number>();
  const [error, setInstanceError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      setInstanceError(undefined);
      setInstanceId(undefined);

      try {
        const inProgressInstanceId =
          (
            await getApi('/reports/checksheet-instances/', {
              columns: ['id'],
              template: +checksheetTemplateId,
              status__in: [Enum.ChecksheetInstance_STATUS.in_progress],
              ordering: ['-created_datetime'],
              limit: 1,
            })
          )[0]?.id ?? null;

        if (inProgressInstanceId) {
          setInstanceId(inProgressInstanceId);
        } else {
          setInstanceError(
            i18n._(
              t`There are no in progress instances of this checksheet template.`
            )
          );
        }
      } catch (e) {
        setInstanceError(errorToString(e));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [checksheetTemplateId]);

  return (
    <PageStandard
      name="resume-checksheet"
      header={<Trans>Resume checksheet</Trans>}
    >
      {isLoading ? (
        <Loading />
      ) : error ? (
        <AlertWarning>{error}</AlertWarning>
      ) : (
        <Redirect to={`/checksheet-instances/${instanceId}`} push={false} />
      )}
    </PageStandard>
  );
}
