import React from 'react';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ReportFilter,
  ReportViewProps,
  ACTION_COLUMN,
  DEFAULT_HIDE,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';
import { TransEnum } from 'components/base/i18n/TransEnum';
import {
  ReportFilterMenu,
  reportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { reportFilterAsyncMenu } from 'components/modules/report/filter/fields/FilterAsyncMenu';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { getApi } from 'util/backendapi/fetch';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { RouteComponentProps } from 'react-router';
import { checksheetTemplateListDuck } from 'ducks/checksheet-template/list';
import { FullState } from 'main/reducers';
import { useReportState } from 'hooks/use-report-state';
import {
  ChecksheetTemplate_FREQUENCY,
  ChecksheetTemplate_STATUS,
} from 'util/backendapi/types/Enum';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { Icon } from 'components/base/icon/icon';
import { DMSLink } from 'components/base/link/DMSLink';

export const checksheetTemplateListColumns: ReportColumn<Model.ReportsChecksheetTemplate>[] =
  [
    {
      label: <Trans>Name</Trans>,
      name: 'name',
      backendFieldName: 'name',
      visibility: DEFAULT_SHOW,
    },
    // TODO: Clients removed from RM74343 AC's
    // {
    //   label: <Trans>Client</Trans>,
    //   name: 'client',
    //   backendFieldName: 'area__client__full_name',
    //   visibility: DEFAULT_SHOW,
    // },
    {
      label: <Trans>Area</Trans>,
      name: 'area',
      backendFieldName: 'areas__code',
      additionalFields: ['areas__name'],
      visibility: DEFAULT_SHOW,
      accessor: ({ areas__code, areas__name }) =>
        `${areas__code} - ${areas__name}`,
    },
    {
      label: <Trans>Frequency</Trans>,
      name: 'frequency',
      backendFieldName: 'frequency',
      visibility: DEFAULT_SHOW,
      accessor: ({ frequency }) => (
        <TransEnum enum="ChecksheetTemplate_FREQUENCY" value={frequency} />
      ),
    },
    {
      label: <Trans>Status</Trans>,
      name: 'status',
      backendFieldName: 'status',
      visibility: DEFAULT_HIDE,
      accessor: ({ status }) => (
        <TransEnum enum="ChecksheetTemplate_STATUS" value={status} />
      ),
    },
    {
      label: <Trans>Plot set</Trans>,
      name: 'plot_set',
      backendFieldName: 'plot_set__name',
      visibility: DEFAULT_SHOW,
    },
    {
      ...ACTION_COLUMN,
      additionalFields: [
        'id',
        'name',
        'status',
        'allow_multiple_instances',
        'in_progress',
      ],
      accessor: ({
        id,
        name,
        status,
        allow_multiple_instances,
        in_progress,
      }) => {
        const canCreateNewChecksheetInstance =
          status === ChecksheetTemplate_STATUS.active &&
          (allow_multiple_instances || !in_progress);

        return (
          <>
            <HasPermission check="can_create_checksheet_instances">
              {canCreateNewChecksheetInstance ? (
                <DMSLink to={`/checksheet-templates/${id}/start`}>
                  <Icon type="icon-circle-plus" title={t`New instance`} />
                </DMSLink>
              ) : null}
              {in_progress ? (
                <DMSLink to={`/checksheet-templates/${id}/resume`}>
                  <Icon type="icon-resume" title={t`Resume`} />
                </DMSLink>
              ) : null}
            </HasPermission>
            <HasPermission check="can_view_checksheet_instances">
              <DMSLink to={`/checksheet-instances/?template=${id}`} key={name}>
                <Icon type="icon-list" title={t`List checksheets`} />
              </DMSLink>
            </HasPermission>
            <HasPermission check="can_create_checksheet_templates">
              <DMSLink to={`/checksheet-templates/${id}`}>
                <Icon type="icon-edit" title={t`Edit`} />
              </DMSLink>
            </HasPermission>
          </>
        );
      },
    },
  ];

function _makeNameMenuItem(
  checksheetTemplate: Model.ChecksheetTemplate
): SimpleSelectOption<number> {
  return {
    value: checksheetTemplate.id,
    label: checksheetTemplate.name,
  };
}

export const checksheetTemplateListFilters: ReportFilter[] = [
  reportFilterAsyncMenu(
    'id',
    <Trans>Name</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (searchText) =>
        getApi('/checksheet-templates/', {
          name__icontains: searchText,
        }).then((checksheetTemplates) =>
          checksheetTemplates.map(_makeNameMenuItem)
        ),
      loadDefaults: (ids: number[]) =>
        getApi('/checksheet-templates/', {
          id__in: ids,
        }).then((checksheetTemplates) =>
          checksheetTemplates.map(_makeNameMenuItem)
        ),
    },
    {
      placeholder: <Trans>All checksheets</Trans>,
      autoFocus: true,
    }
  ),
  // TODO: Clients removed from RM74343 AC's
  // reportFilterMenu(
  //   'area__client',
  //   <Trans>Client</Trans>,
  //   { isMulti: true, valueType: 'number' },
  //   (option: Model.Client) => ({
  //     value: option.id,
  //     label: `${option.full_name}`,
  //   }),
  //   { placeholder: <Trans>All clients</Trans> }
  // ),
  reportFilterMenu(
    'areas',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All areas</Trans> }
  ),
  reportFilterMenu(
    'frequency',
    <Trans>Frequency</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('ChecksheetTemplate_FREQUENCY'),
    {
      placeholder: <Trans>All frequencies</Trans>,
      options: Object.values(ChecksheetTemplate_FREQUENCY),
    }
  ),
  reportFilterMenu(
    'status',
    <Trans>Status</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('ChecksheetTemplate_STATUS'),
    {
      placeholder: <Trans>All statuses</Trans>,
      options: Object.values(ChecksheetTemplate_STATUS),
    }
  ),
];

export function ChecksheetTemplateListScreen(props: RouteComponentProps) {
  const reportState = useReportState(
    props,
    checksheetTemplateListColumns,
    checksheetTemplateListFilters,
    checksheetTemplateListDuck,
    (state: FullState) => state.checksheetTemplate.list
  );

  return <ChecksheetTemplateListView reportProps={reportState} />;
}

export function ChecksheetTemplateListView(props: {
  reportProps: ReportViewProps<Model.ReportsChecksheetTemplate>;
}) {
  const { reportProps } = props;
  const columns = checksheetTemplateListColumns;
  const filters = checksheetTemplateListFilters;
  return (
    <PageStandard
      name="checksheet-templates-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Checksheet templates report</Trans>
        ) : (
          <Trans>Maintain Checksheet Templates</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <HasPermission check="can_create_checksheet_templates">
          <ActionBlock className="text-right">
            <DMSLink
              to={`/checksheet-templates/new`}
              className="btn"
              id="create-new-checksheet-template"
              shortcut="CREATE_NEW"
            >
              <span>
                <Trans>Create checksheet template</Trans>
              </span>
              <Icon type="icon-plus" />
            </DMSLink>
          </ActionBlock>
        </HasPermission>
        <ReportFiltersBlock
          filtersFrontend={filters}
          filtersBackend={reportProps.reportInfo?.filters}
          isExportMode={reportProps.isExportMode}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <ButtonPrint />
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Checksheet templates report`}
            reportUrl="/reports/checksheet-templates/"
            columns={columns}
            filters={filters}
          />
        </ActionBlock>

        <ReportTable<Model.ReportsChecksheetTemplate>
          columns={columns}
          filters={filters}
          {...reportProps}
        />
      </div>
    </PageStandard>
  );
}
