import React from 'react';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, Plural, t } from '@lingui/macro';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ReportFilter,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { Icon } from 'components/base/icon/icon';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { TransEnum } from 'components/base/i18n/TransEnum';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { CreateStoredPlotModal } from './CreateStoredPlotModal';
import { ReportStateProps } from 'hooks/use-report-state';
import { DMSLink } from 'components/base/link/DMSLink';
import { reportFilterAsyncMenu } from 'components/modules/report/filter/fields/FilterAsyncMenu';
import { getApi } from 'util/backendapi/fetch';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';

export interface StoredPlotListViewProps {
  reportProps: ReportStateProps<Model.ReportsStoredPlot>;
  deleteStoredPlot: (id: number) => Promise<any>;
}

export const storedPlotListColumns: (props: {
  deleteStoredPlot: (id: number) => Promise<void>;
  refreshList: () => void;
}) => ReportColumn<Model.ReportsStoredPlot>[] = ({
  deleteStoredPlot,
  refreshList,
}) => [
  {
    label: <Trans>Plot name</Trans>,
    name: 'name',
    visibility: DEFAULT_SHOW,
    accessor: ({ name }) => (
      <DMSLink to={`/stored-plots/${name}`}>{name}</DMSLink>
    ),
  },
  {
    label: <Trans>Plot type</Trans>,
    name: 'plot_type',
    visibility: DEFAULT_SHOW,
    accessor: ({ plot_type }) => (
      <TransEnum enum="PlotType" value={plot_type} />
    ),
  },
  {
    label: <Trans>Title</Trans>,
    name: 'title',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Description</Trans>,
    name: 'description',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Area</Trans>,
    name: 'area',
    backendFieldName: 'area__code',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Observation points</Trans>,
    name: 'observation_point',
    backendFieldName: 'observation_point_count',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Plot set</Trans>,
    name: 'plot_set_names',
    visibility: DEFAULT_SHOW,
  },
  {
    ...ACTION_COLUMN,
    additionalFields: ['id', 'name', 'plot_type'],
    accessor: ({ id, name, plot_type }) => (
      <>
        <DMSLink to={`/stored-plots/${name}`}>
          <Icon type="icon-view" title={t`View`} />
        </DMSLink>
        <HasPermission check="can_create_stored_plots">
          <DMSLink to={`/stored-plots/${name}/edit`}>
            <Icon type="icon-edit" title={t`Edit`} />
          </DMSLink>
        </HasPermission>
        <HasPermission check="can_delete_stored_plots">
          <ButtonShowConfirmation
            name="delete-stored-plot"
            title={t`Delete`}
            iconType="icon-circle-minus"
            className="btn-link-panel"
            content={
              <Trans>
                Are you sure you want to delete the <strong>{name}</strong>{' '}
                stored plot? This action is not reversible.
              </Trans>
            }
            okBtnText={<Trans>Yes, delete</Trans>}
            onConfirm={async function () {
              await deleteStoredPlot(id);
              refreshList();
            }}
          />
        </HasPermission>
      </>
    ),
  },
];

function makeIdNameMenuOptions(items: { id: number; name: string }[]) {
  return items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
}

function makeAreaMenuOptions(items: Model.AreaDecorated[]) {
  return items.map(ReportFilterMenu.CODE_AND_NAME_MENU);
}

export const storedPlotListFilters: ReportFilter[] = [
  reportFilterAsyncMenu(
    'id',
    <Trans>Plot name</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (inputValue) =>
        getApi(`/reports/stored-plots/`, {
          name__icontains: inputValue,
          limit: 50,
          columns: ['id', 'name'],
        }).then(makeIdNameMenuOptions),
      loadDefaults: (ids: number[]) =>
        getApi(`/reports/stored-plots/`, {
          id__in: ids,
          columns: ['id', 'name'],
        }).then(makeIdNameMenuOptions),
    },
    {
      placeholder: <Trans>All stored plots</Trans>,
      autoFocus: true,
    }
  ),
  reportFilterMenu(
    'plot_type',
    <Trans>Plot type</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('PlotType'),
    {
      placeholder: <Trans>All plot types</Trans>,
    }
  ),
  reportFilterAsyncMenu(
    'area',
    <Trans>Area</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (inputValue) =>
        getApi('/areas/', { code__icontains: inputValue }).then(
          makeAreaMenuOptions
        ),
      loadDefaults: (ids: number[]) =>
        getApi('/areas/', { id__in: ids }).then(makeAreaMenuOptions),
    },
    {
      placeholder: <Trans>Select area(s)</Trans>,
    }
  ),
  reportFilterAsyncMenu(
    'plot_set',
    <Trans>Plot set</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (inputValue) =>
        getApi('/plot-sets/', {
          name__icontains: inputValue,
          fields: ['id', 'name'],
        }).then(makeIdNameMenuOptions),
      loadDefaults: (ids: number[]) =>
        getApi('/plot-sets/', { id__in: ids, fields: ['id', 'name'] }).then(
          makeIdNameMenuOptions
        ),
    },
    { placeholder: <Trans>All plot sets</Trans> }
  ),
];

export const StoredPlotListView = (props: StoredPlotListViewProps) => {
  const { reportProps } = props;

  const columns = storedPlotListColumns({
    refreshList: reportProps.refreshList,
    deleteStoredPlot: props.deleteStoredPlot,
  });

  return (
    <PageStandard
      name="stored-plot-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Stored Plots report</Trans>
        ) : (
          <Trans>Maintain Stored Plots</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <HasPermission check="can_create_stored_plots">
          <ActionBlock className="text-right">
            <ButtonShowModal
              name="create-stored-plot"
              iconType="icon-plus"
              shortcut="CREATE_NEW"
              modalContent={() => <CreateStoredPlotModal />}
            >
              <Trans>Create stored plot</Trans>
            </ButtonShowModal>
          </ActionBlock>
        </HasPermission>

        <ReportFiltersBlock
          filtersBackend={
            reportProps.reportInfo && reportProps.reportInfo.filters
          }
          filtersFrontend={storedPlotListFilters}
          isExportMode={reportProps.isExportMode}
        />
      </div>

      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={storedPlotListFilters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Stored plots report`}
            reportUrl="/reports/stored-plots/"
            columns={columns}
            filters={storedPlotListFilters}
          />
        </ActionBlock>
        <ReportTable
          columns={columns}
          filters={storedPlotListFilters}
          reportInfo={reportProps.reportInfo}
          errorMessage={reportProps.errorMessage}
          isLoading={reportProps.isLoading}
          pagination={reportProps.pagination}
          records={reportProps.records}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 stored plot"
              other="Filtered to # stored plots"
            />
          )}
          msgNoMatches={
            <Trans>No stored plots match the selected filters.</Trans>
          }
        />
      </div>
    </PageStandard>
  );
};
