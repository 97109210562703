import { getApi, getPaginated } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { PerformanceIndicator_STATUS } from 'util/backendapi/types/Enum';

export const performanceIndicatorListDuck = makeReportingDuck(
  'inspectionBatch',
  (filters: Filter.ReportsPerformanceIndicators) =>
    getPaginated('/reports/performance-indicators/', {
      ...filters,
      status: PerformanceIndicator_STATUS.active,
    }),
  () => getApi('/reports/performance-indicators/info/')
);

export type PerformanceIndicatorListActions = ReportingDuckActions<
  typeof performanceIndicatorListDuck
>;
